import React from 'react'

const Header = () => {
  return (
    <div className="header">
       <img src="/images/background1.png" alt="background" />

        
        <div className='headertext'>
          <p className='headertext3'>"WELCOME TO GC TRADE"</p>
          <p className='headertext1'>YOUR GATEWAY TO</p>
          <p className='headertext2'>Global Commodity</p>
        </div>
    </div>
   
  )
}

export default Header